:root {
  --color-blue: #2f5597;
  --color-dark-green: #2e6053;
  --color-light-green: #bbddd0;
  --color-dark-red: #bb442f;
  --color-light-red: #bb442f40;
  --color-icon-green: #39b883;
  --color-yellow: #f4c118;
  --color-aqua: #a1c6ea;
  --color-light-blue: #a1c6ea40;
  --color-beige: #f2f4f1;
  --color-light-grey: #ddd;
  --color-medium-grey: #787878;
  --color-dark-grey: #4b4b4b;
  --color-icon-grey: #aaa8a8;
  --color-black: #2b2b2b;
}

body {
  font-family: "Calibri", sans-serif;
}
